import { z } from 'zod';

export const GetChatsByUserResponseSchema = z.object({
  docs: z.array(
    z.object({
      id: z.string(),
      car_id: z.string(),
      car_name: z.string(),
      createdAt: z.string().pipe(z.coerce.date()),
      customer_id: z.string(),
      customer_name: z.string().optional(),
      customer_picture: z.string().nullish(),
      host_id: z.string(),
      host_name: z.string().optional(),
      host_picture: z.string().nullish(),
      is_customer_read: z.boolean(),
      is_host_read: z.boolean(),
      last_message: z.string(),
      last_message_date: z.string().pipe(z.coerce.date()),
      updatedAt: z.string().pipe(z.coerce.date())
    })
  ),
  errors_count: z.number(),
  limit: z.number(),
  page: z.number(),
  pages: z.number(),
  total: z.number(),
  unread_count: z.number()
});

export const ChatByUserSchema = z.object({
  _id: z.string(),
  car_id: z.string(),
  car_name: z.string(),
  createdAt: z.string().pipe(z.coerce.date()),
  customer_id: z.string(),
  customer_name: z.string(),
  customer_picture: z.string().nullish(),
  host_id: z.string(),
  host_name: z.string().nullish(),
  host_picture: z.string().nullish(),
  is_customer_read: z.boolean(),
  is_host_read: z.boolean(),
  updatedAt: z.string().pipe(z.coerce.date())
});

export const MessageSchema = z.object({
  chat_id: z.string(),
  content: z.string().optional(),
  createdAt: z.string().pipe(z.coerce.date()),
  id: z.string(),
  sender_id: z.string(),
  type: z.string(),
  updatedAt: z.string().pipe(z.coerce.date())
});

export const ChatMessagesSchema = z.object({
  docs: z.array(MessageSchema).default([]),
  limit: z.number(),
  page: z.number(),
  pages: z.number(),
  total: z.number()
});

export const ChatByVehicleSchema = z.object({
  _id: z.string().optional(),
  id: z.string().optional(),
  customer_id: z.string(),
  car_id: z.string(),
  car_name: z.string(),
  host_id: z.string(),
  host_picture: z.string().nullish(),
  host_name: z.string(),
  is_customer_read: z.boolean(),
  is_host_read: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string()
});

export type RentennialsChatsByUser = z.infer<typeof GetChatsByUserResponseSchema>;
export type RentennialsChatByUser = z.infer<typeof ChatByUserSchema>;
export type RentennialsMessage = z.infer<typeof MessageSchema>;
export type RentennialsChatMessages = z.infer<typeof ChatMessagesSchema>;
export type RentennialsChatByVehicle = z.infer<typeof ChatByVehicleSchema>;
