import { FC, PropsWithChildren } from 'react';
import { useIsRoutePathChanging } from '@utils/hooks/useRouteTransitioning';

import Loading from './Loading';

const GlobalLayout: FC<PropsWithChildren> = ({ children }) => {
  const isRouteChange = useIsRoutePathChanging();
  return <div className="h-full">{isRouteChange ? <Loading /> : children}</div>;
};

export default GlobalLayout;
