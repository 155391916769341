export const checkIfParent = (element: Partial<HTMLElement>, elementId: string): boolean => {
  if (element.id === elementId) {
    return true;
  }
  if (!element.parentElement) {
    return false;
  }
  return checkIfParent(element.parentElement, elementId);
};

export const toRegularCharsWithoutSpacesAndLower = (value: string) =>
  value
    .normalize('NFD')
    .replaceAll(/[\u0300-\u036f]/g, '')
    .replaceAll(' ', '')
    .toLowerCase();

export const getElement = <T extends object>(data: T, key: keyof T) => {
  return data[key];
};

export const groupByClause = <T extends object, K extends string>(
  data: T[],
  predicate: (item: T) => K
): Record<K, T[]> => {
  return data.reduce((acc, item) => {
    const key = predicate(item);
    if (getElement(acc, key)) {
      acc[key].push(item);
    } else {
      acc[key] = [item];
    }
    return acc;
  }, {} as Record<K, T[]>);
};
