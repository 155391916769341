import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';

type UrlGuard = (url: string) => boolean;
type RouteChangeEvent = (url: string) => void;

const getQueryFromUrlPath = (url: string): ParsedUrlQuery => {
  const nextUrl = new URL(url, window.origin);
  return Object.fromEntries(nextUrl.searchParams.entries());
};

const doUrlQueriesMatch = (leftQuery: ParsedUrlQuery, rightQuery: ParsedUrlQuery) => {
  const allQueryKeys = [...Object.keys(leftQuery), ...Object.keys(rightQuery)];
  return allQueryKeys.some((q) => leftQuery[q] !== rightQuery[q]);
};

/**
 * Returns the current route.
 */
export const useCurrentRoute = () => {
  const router = useRouter();
  const currentPathWithLocale = '/' + router.locale + router.asPath;
  const currentRoute = router.locale === router.defaultLocale ? router.asPath : currentPathWithLocale;

  return currentRoute;
};

/**
 * Returns the next route that is about to be navigated to, or undefined if no route change is in progress.
 */
export const useNextRoute = () => {
  const router = useRouter();

  const [nextRoute, setNextRoute] = useState<string>();

  useEffect(() => {
    const routeChangeCompleted: RouteChangeEvent = () => {
      setNextRoute(undefined);
    };

    router.events.on('routeChangeStart', setNextRoute);
    router.events.on('routeChangeComplete', routeChangeCompleted);

    return () => {
      router.events.off('routeChangeStart', setNextRoute);
      router.events.off('routeChangeComplete', routeChangeCompleted);
    };
  }, [router.events]);

  return nextRoute;
};

export const useRouteQuery = () => {
  const router = useRouter();
  const nextRoute = useNextRoute();

  return nextRoute ? getQueryFromUrlPath(nextRoute) : router.query;
};

/**
 * Track route changes.
 * @param urlGuard Function that returns true if the route change should be considered a transition.
 */
export const useRouteGuard = (urlGuard: UrlGuard) => {
  const nextRoute = useNextRoute();
  return nextRoute ? urlGuard(nextRoute) : false;
};

/**
 * Tracks when the route only changes its search params.
 */
export const useIsRouteQueryChanging = () => {
  const router = useRouter();
  return useRouteGuard((urlPath) => doUrlQueriesMatch(router.query, getQueryFromUrlPath(urlPath)));
};

export const parsePathName = (url: string) => {
  // This error is required to prevent hydration mismatches.
  if (url.startsWith('http')) {
    throw new Error('doPathnamesMatch() does not support absolute URLs');
  }

  const pathname = url.split('?')[0];
  return pathname;
};

/**
 * Tracks when the route changes to a different path.
 */
export const useIsRoutePathChanging = () => {
  const currentRoute = useCurrentRoute();
  return useRouteGuard((urlPath) => parsePathName(currentRoute) !== parsePathName(urlPath));
};

/**
 * Checks if the **current OR future route** matches the given path.
 */
export const useRouteMatch = (pathname: string, options: { exact?: boolean } = {}): boolean => {
  const { exact } = options;

  const currentRoute = useCurrentRoute();
  const nextRoute = useNextRoute();

  const checkForMatch = (p: string) => {
    return exact ? p === pathname : p.includes(pathname);
  };

  return checkForMatch(nextRoute || currentRoute);
};
