import { useCallback, useEffect, useState } from 'react';
import { getChats } from '@api/data/chats';
import Button from '@components/common/Button';
import TopMenu from '@components/menu/RentennialsMenu';
import { ACCESS_TOKEN_COOKIE_KEY } from '@constants/index';
import { useStickyHeader } from '@utils/hooks/useStickyHeader';
import { tw } from '@utils/tailwind-utils';
import Cookies from 'js-cookie';
import { useTranslation } from 'next-i18next';

const HeaderMenu = () => {
  const { t } = useTranslation('menu');
  const isSticky = useStickyHeader();
  const [hasMessagesUnread, setHasMessagesUnread] = useState<boolean>(false);
  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_KEY);

  const fetchMessages = useCallback(async () => {
    const response = await getChats({ page: 1, limit: 1000 }, accessToken ?? '');
    const chats = response?.docs ?? [];
    setHasMessagesUnread(chats?.some((chat) => !chat.isCustomerRead));
  }, [accessToken]);

  useEffect(() => {
    if (!accessToken || accessToken?.length === 0) {
      return;
    }

    fetchMessages();

    const intervalMessages = setInterval(async () => {
      fetchMessages();
    }, 5000);

    return () => clearInterval(intervalMessages);
  }, [accessToken, fetchMessages]);

  return (
    <div className={tw('rentennials-landing-menu', { notSticky: !isSticky })}>
      <TopMenu
        hasBg={isSticky}
        className={tw('justify-center', { 'fixed z-20  animate-fade pb-5 ': isSticky })}
        containerClassName="flex w-[1200px] items-center justify-between pt-4  mobile:px-4"
        logoClassName="min-w-[180px]"
        rightTitleElement={
          <div className="mobile:hidden tablet:block">
            <a
              href="https://blog.rentennials.app/como-alquilar-tus-vehiculos/"
              target="_blank"
              rel="noopener noreferrer"
              title={t('become-host')}
            >
              <Button
                className={tw('mx-4 h-[35px] w-[140px] rounded-[20px] bg-white hover:bg-white hover:opacity-50 ', {
                  'bg-rentennials-primary hover:bg-rentennials-secondary hover:opacity-100': isSticky
                })}
              >
                <span
                  className={tw(
                    'px-2 pb-1 font-bree-serif text-[14px] font-normal leading-[18px] tracking-wider text-rentennials-primary hover:text-white',
                    {
                      ' text-white': isSticky
                    }
                  )}
                >
                  {t('become-host')}
                </span>
              </Button>
            </a>
          </div>
        }
        hasMessagesUnread={hasMessagesUnread}
      >
        <div
          className={tw(
            'hidden font-bree-serif text-[15px] leading-[25px] text-transparent text-white tablet:gap-x-2 lg:flex lg:gap-x-8',
            {
              'text-rentennials-primary': isSticky
            }
          )}
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://blog.rentennials.app/"
            title={t('bar.how-it-works')}
          >
            {t('bar.how-it-works')}
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://blog.rentennials.app/faqs/" title={t('bar.faq')}>
            {t('bar.faq')}
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://blog.rentennials.app/destinos/"
            title={t('bar.destination')}
          >
            {t('bar.destination')}
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://blog.rentennials.app/blog" title={t('bar.blog')}>
            {t('bar.blog')}
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://corporativo.rentennials.app/"
            title={t('bar.business')}
          >
            {t('bar.business')}
          </a>
        </div>
      </TopMenu>
    </div>
  );
};

export default HeaderMenu;
