import {
  ChatByVehicle,
  ChatMessages,
  ChatUser,
  GetChatsByUserResponse,
  Message,
  MessageSocket
} from '@api/types/chats';
import {
  RentennialsChatByUser,
  RentennialsChatByVehicle,
  RentennialsChatMessages,
  RentennialsChatsByUser
} from '@api/types/chatsSchema';
import { getInfoSender } from '@utils/chats';
import { deepKeysToCamel } from '@utils/convertCase';

export const transformChats = (chats: RentennialsChatsByUser) => {
  return deepKeysToCamel<RentennialsChatsByUser, GetChatsByUserResponse>(chats);
};

export const transformChat = (chat: RentennialsChatByUser) => {
  return deepKeysToCamel<RentennialsChatByUser, ChatUser>(chat);
};

export const transformChatMessages = (chatMessages: RentennialsChatMessages, chat: ChatUser) => {
  const messages = chatMessages.docs
    .filter((msg) => msg.content)
    .map((message) => ({
      ...message,
      ...getInfoSender(message.sender_id, chat)
    }));
  return deepKeysToCamel<RentennialsChatMessages, ChatMessages>({ ...chatMessages, docs: messages });
};

export const transformMessage = (message: MessageSocket, chat: ChatUser) => {
  const messageParsed = {
    ...message,
    ...getInfoSender(message.sender_id, chat)
  };
  return deepKeysToCamel<MessageSocket, Message>({ ...messageParsed });
};

export const transformChatByVehicle = (chat: RentennialsChatByVehicle) => {
  return deepKeysToCamel<RentennialsChatByVehicle, ChatByVehicle>(chat);
};
