import { PaginationChats } from '@api/types/pagination';
import { BASE_MESSAGES_URL } from '@config/index';
import { formatUrl } from 'url-lib';

import { sdkAuthRequest } from '../sdk';
import {
  ChatByUserSchema,
  ChatByVehicleSchema,
  ChatMessagesSchema,
  GetChatsByUserResponseSchema,
  RentennialsChatByUser,
  RentennialsChatByVehicle,
  RentennialsChatMessages,
  RentennialsChatsByUser
} from '../types/chatsSchema';

export const getChats = async (
  { page, limit }: PaginationChats,
  accessToken: string
): Promise<RentennialsChatsByUser> => {
  const response = await sdkAuthRequest(
    formatUrl('chats', {
      page_size: limit.toString(),
      page: page.toString()
    }),
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    },
    accessToken,
    BASE_MESSAGES_URL
  );
  return GetChatsByUserResponseSchema.parse(response);
};

export const getChat = async (chatId: string, accessToken: string): Promise<RentennialsChatByUser> => {
  const response = await sdkAuthRequest(
    `chats/${chatId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    },
    accessToken,
    BASE_MESSAGES_URL
  );
  return ChatByUserSchema.parse(response);
};

export const getChatMessages = async (
  chatId: string,
  { page, limit }: PaginationChats,
  accessToken: string
): Promise<RentennialsChatMessages> => {
  const response = await sdkAuthRequest(
    formatUrl(`chats/${chatId}/messages`, {
      page_size: limit.toString(),
      page: page.toString()
    }),
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    },
    accessToken,
    BASE_MESSAGES_URL
  );
  return ChatMessagesSchema.parse(response);
};

export const getChatByVehicle = async (vehicleId: string, accessToken: string): Promise<RentennialsChatByVehicle> => {
  const response = await sdkAuthRequest(
    `chats/products/${vehicleId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    },
    accessToken,
    BASE_MESSAGES_URL
  );
  return ChatByVehicleSchema.parse(response);
};

export const createNewChatProduct = async (
  vehicleId: string,
  message: { content: string },
  accessToken: string
): Promise<RentennialsChatByVehicle> => {
  const response = await sdkAuthRequest(
    `chats/products/${vehicleId}`,
    {
      method: 'POST',
      body: JSON.stringify(message),
      headers: {
        'Content-Type': 'application/json'
      }
    },
    accessToken,
    BASE_MESSAGES_URL
  );
  return ChatByVehicleSchema.parse(response);
};
