import { useEffect, useState } from 'react';

export const useStickyHeader = () => {
  const [isSticky, setIsSticky] = useState<boolean>(false);

  const handleStickyBar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      setIsSticky(windowHeight > 60);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleStickyBar);
    return () => window.removeEventListener('scroll', handleStickyBar);
  }, []);

  return isSticky;
};
