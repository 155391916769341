import { ChatUser } from '@api/types/chats';

interface UserInfo {
  userPicture: string;
  userName: string;
  isHost: boolean;
}

export const getInfoSender = (senderId: string, chat: ChatUser): UserInfo => {
  let dataUser = {} as UserInfo;

  if (senderId === chat.hostId) {
    dataUser.userName = chat.hostName;
    dataUser.userPicture = chat.hostPicture;
    dataUser.isHost = true;
  }

  if (senderId === chat.customerId) {
    dataUser.userName = chat.customerName;
    dataUser.userPicture = chat.customerPicture;
    dataUser.isHost = false;
  }

  return { ...dataUser };
};
