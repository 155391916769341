import { getStories as getStoriesApi, StorieApiQueryOptions } from '@api/endpoints/stories';
import {
  incrementInteractions as incrementInteractionsApi,
  incrementViews as incrementViewsApi
} from '@api/endpoints/stories';

export const getStories = async (searchParams?: StorieApiQueryOptions) => {
  try {
    return await getStoriesApi(searchParams);
  } catch (e) {
    console.log(e);
  }
};

export const incrementInteractions = async (id: string) => {
  try {
    return await incrementInteractionsApi(id);
  } catch (error) {}
};

export const incrementViews = async (id: string) => {
  try {
    return await incrementViewsApi(id);
  } catch (error) {}
};
