import { SearchParamsType } from '@api/types/searchParamsSchema';
import { VehicleSortKey } from '@api/types/vehicles';
import { FilterForm } from '@components/listing/FilterMenuContent';
import { DEFAULT_VEHICLE_SORT_KEY, MAX_PRICE, MIN_PRICE } from '@constants/index';
import { DEFAULT_SEARCH_VALUES } from '@utils/dates';
import { VehicleApiQueryOptions } from 'src/api/endpoints/vehicles';
import { create } from 'zustand';

interface VehicleSearchFilterCriteriaStoreType {
  filters?: FilterForm;
  setFilters: (filters: FilterForm) => void;
  sort: VehicleSortKey;
  setSort: (sort: VehicleSortKey) => void;
  searchVehicleParams: VehicleApiQueryOptions;
  setSearchVehicleParams: (vehicleSearchParams: VehicleApiQueryOptions) => void;
  searchQueryParams?: SearchParamsType;
  setSearchQueryParams: (searchQueryParams: SearchParamsType) => void;
  reset: () => void;
  priceRange: {
    minPrice: number;
    maxPrice: number;
  };
  setPriceRange: (minPrice?: number, maxPrice?: number) => void;
}

export const useVehicleSearchFilter = create<VehicleSearchFilterCriteriaStoreType>((set) => ({
  filters: undefined,
  setFilters: (filters?: FilterForm) =>
    set(() => ({
      filters
    })),
  sort: DEFAULT_VEHICLE_SORT_KEY,
  setSort: (sort: VehicleSortKey) =>
    set(() => ({
      sort
    })),
  searchVehicleParams: {
    fromDate: DEFAULT_SEARCH_VALUES.fromDate,
    toDate: DEFAULT_SEARCH_VALUES.toDate,
    fromDateTime: DEFAULT_SEARCH_VALUES.fromDateTime,
    toDateTime: DEFAULT_SEARCH_VALUES.toDateTime
  },
  setSearchVehicleParams: (searchVehicleParams: VehicleApiQueryOptions) =>
    set(() => ({
      searchVehicleParams
    })),
  searchQueryParams: undefined,
  setSearchQueryParams: (searchQueryParams: SearchParamsType) =>
    set(() => ({
      searchQueryParams
    })),
  priceRange: {
    maxPrice: Number.MAX_SAFE_INTEGER,
    minPrice: MIN_PRICE
  },
  setPriceRange: (minPrice?: number, maxPrice?: number) =>
    set(({ priceRange }) => ({
      priceRange: {
        minPrice: minPrice || priceRange.minPrice,
        maxPrice: maxPrice || priceRange.maxPrice
      }
    })),
  reset: () =>
    set(() => ({
      filters: undefined,
      sort: DEFAULT_VEHICLE_SORT_KEY,
      searchVehicleParams: {
        fromDate: DEFAULT_SEARCH_VALUES.fromDate,
        toDate: DEFAULT_SEARCH_VALUES.toDate
      },
      priceRange: {
        minPrice: MIN_PRICE,
        maxPrice: MAX_PRICE
      }
    }))
}));
