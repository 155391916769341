import { SearchParamsType } from '@api/types/searchParamsSchema';
import HeaderMenu from '@components/landing/HeaderMenu';
import GlobalLayout from '@components/layout/GlobalLayout';
import Loading from '@components/layout/Loading';
import Search from '@components/searchBar/Search';
import { DEFAULT_LOCALE, WEB_SITE } from '@constants/index';
import { useRentennialsStories } from '@store/stories';
import { useVehicleSearchFilter } from '@store/vehicleSearhFilterCriteria';
import { allCities } from '@utils/cities';
import { DEFAULT_SEARCH_VALUES } from '@utils/dates';
import { startProfiler, stopProfiler } from '@utils/profiler';
import { GetStaticProps, InferGetStaticPropsType } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { getCities } from 'src/api/data/cities';
import { getVehiclesRated } from 'src/api/data/vehicles';

const LandingIndex = dynamic(() => import('@components/pages/LandingIndex'), {
  ssr: false,
  loading: () => <Loading />
});

export const getStaticProps: GetStaticProps = (async ({ locale, defaultLocale }) => {
  const profilerKey = startProfiler('landing-api-call');
  const i18n = await serverSideTranslations(locale || defaultLocale || DEFAULT_LOCALE, [
    'landing',
    'common',
    'search',
    'footer',
    'menu',
    'share',
    'countries',
    'stories'
  ]);

  const [cities, vehiclesRated] = await Promise.all([getCities(), getVehiclesRated()]);
  stopProfiler('landing-api-call', profilerKey);

  return {
    props: {
      ...i18n,
      locale: locale || defaultLocale || DEFAULT_LOCALE,
      cities: cities && cities.length ? cities : allCities,
      vehicles: vehiclesRated!
    },
    revalidate: 10800
  };
}) satisfies GetStaticProps;

const LandingPage = (props: InferGetStaticPropsType<typeof getStaticProps>) => {
  const { locale, cities } = props;
  const router = useRouter();
  const { t } = useTranslation(['landing', 'common', 'countries']);
  const { reset } = useRentennialsStories();
  const { reset: resetFilters } = useVehicleSearchFilter();

  const handleLoadListing = async (data: SearchParamsType) => {
    resetFilters();
    reset();
    await router.push({
      pathname: '/listing',
      query: {
        ...(Object.keys(data) as Array<keyof typeof data>).reduce((acc, key) => {
          return data[key]
            ? {
                ...acc,
                [key]: data[key]
              }
            : acc;
        }, {} as SearchParamsType)
      }
    });
  };

  return (
    <GlobalLayout>
      <Head>
        <title>Alquiler de autos | Rent a car</title>
        <meta property="og:site_name" content="Rentennials" />
        <meta property="og:title" content={t('landing.metadata.title')} />
        <meta
          name="description"
          content={
            'Rentennials es la forma más simple, económica y rápida de alquilar vehículos de anfitriones locales. Únete a la comunidad Rent a Car.'
          }
        />
        <meta property="og:description" content={t('landing.metadata.description')} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={typeof window !== 'undefined' ? window.location.href : ''} />
        <meta property="og:image:background_color" content="#ffffff" />
        <meta property="og:image:width" content="135" />
        <meta property="og:image:height" content="155" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image" content={`${WEB_SITE}images/Rentennials_Responsive_Logo.png`} />
        <meta property="og:ttl" content="600" />
        <meta property="og:locale" content={locale} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <header
        className="h-[380px] w-full bg-cover bg-no-repeat bg-blend-luminosity tablet:h-[360px] lg:h-[311px]"
        style={{
          backgroundImage: 'url(/images/Top_Background.svg)'
        }}
      >
        <div className="flex h-full flex-col justify-between">
          <div className="flex h-full w-full flex-col">
            <HeaderMenu />
            <div className="rentennials-landing-message flex w-full justify-center">
              <div className="z-[10] pt-14 text-center font-bree-serif font-normal leading-10 text-white max-[850px]:pt-4 max-[850px]:leading-9 min-[600px]:pt-12 min-[600px]:leading-9 tablet:pt-12 tablet:leading-9 lg:pt-14 lg:leading-normal">
                <Trans
                  t={t}
                  i18nKey="landing.title"
                  components={{
                    text1: (
                      <h1 className="text-[22px] max-[600px]:mb-[11px] max-[600px]:mt-[6px] max-[600px]:leading-5 max-[400px]:text-[24px] max-[320px]:text-[18px] min-[401px]:text-[28px] tablet:text-[36px] lg:text-[42px]" />
                    ),
                    text2: (
                      <h2 className="text-[14px] max-[600px]:leading-5 max-[400px]:text-[16px] max-[320px]:text-[16px] min-[401px]:text-[18px] tablet:text-[18px] lg:text-[22px]" />
                    )
                  }}
                />
              </div>
            </div>
          </div>
          <div className="relative flex w-full justify-center">
            <Search
              onSearchBarSubmit={handleLoadListing}
              cities={cities}
              currentDefaultSearchValues={DEFAULT_SEARCH_VALUES}
              className="min-[600px] absolute top-[-210px] flex w-[90%] flex-col 
              gap-x-3 gap-y-4 rounded-[40px] bg-white px-10 py-7 shadow-lg min-[601px]:top-[-130px] tablet:flex-col lg:top-[-67px] lg:w-[1100px] lg:flex-row"
            />
          </div>
        </div>
      </header>
      <LandingIndex cities={cities} locale={locale} {...props} />
    </GlobalLayout>
  );
};

export default LandingPage;
