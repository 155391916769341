import { RentennialsStories } from '@api/types/stories';
import { LS_STORIES_IDS_KEY } from '@constants/index';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface RentennialsStoriesStoreType {
  stories: Array<RentennialsStories>;
  setStories: (stories: Array<RentennialsStories>) => void;
  reset: () => void;
}

export const useRentennialsStories = create(
  persist<RentennialsStoriesStoreType>(
    (set) => ({
      stories: [],
      setStories: (newStories) =>
        set(() => ({
          stories: newStories
        })),
      reset: () => {
        set({
          stories: []
        });
      }
    }),
    { name: LS_STORIES_IDS_KEY }
  )
);
