import { z } from 'zod';

export const RentennialsStoriesSchema = z.object({
  data: z
    .array(
      z.object({
        id: z.string(),
        imgUrl: z.string(),
        name: z.string(),
        stories: z.array(
          z.object({
            id: z.string(),
            source: z.object({
              uri: z.string()
            }),
            mediaType: z.string().optional()
          })
        ),
        vehicle: z.object({
          id: z.string(),
          slug: z.string()
        })
      })
    )
    .default([]),
  page: z.number(),
  page_size: z.number(),
  sort: z.string().optional(),
  sort_dir: z.string()
});
