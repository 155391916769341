import { transformChat, transformChatByVehicle, transformChatMessages, transformChats } from '@api/transform/chats';
import { ChatUser } from '@api/types/chats';
import { PaginationChats } from '@api/types/pagination';

import {
  createNewChatProduct as createNewChatProductApi,
  getChat as getChatApi,
  getChatByVehicle as getChatsByVehicleApi,
  getChatMessages as getChatMessagesApi,
  getChats as getChatsApi
} from '../endpoints/chats';

export const getChats = async (params: PaginationChats, accessToken: string) => {
  try {
    return transformChats(await getChatsApi(params, accessToken));
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const getChat = async (chatId: string, accessToken: string) => {
  try {
    return transformChat(await getChatApi(chatId, accessToken));
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const getChatMessages = async (
  { chat, params }: { chat: ChatUser; params: PaginationChats },
  accessToken: string
) => {
  try {
    return transformChatMessages(await getChatMessagesApi(chat.id, params, accessToken), chat);
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const getChatByVehicle = async (vehicleId: string, accessToken: string) => {
  try {
    return transformChatByVehicle(await getChatsByVehicleApi(vehicleId, accessToken));
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const createNewChatProduct = async (vehicleId: string, message: { content: string }, accessToken: string) => {
  try {
    return transformChatByVehicle(await createNewChatProductApi(vehicleId, message, accessToken));
  } catch (error) {
    console.log(error);
    return null;
  }
};
